import { InferType, array, bool, number, object } from 'yup'
import { blockComponentSchema } from '../../components/blockComponentSchema'
import { buttonComponentSchema } from '../../components/buttonComponentSchema'
import { containerComponentSchema } from '../../components/containerComponentSchema'
import { mediaComponentSchema } from '../../components/mediaComponentSchema'
import { textComponentSchema } from '../../components/textComponentSchema'
import { blockEditorSchema } from '../../shared/blockEditorSchema'
import { idSchema } from '../../shared/idSchema'
import { version } from '../../shared/versionSchema'
import {
  sliderVariant1ComponentSchema,
  sliderVariant1ComponentsSchema,
} from '../../components/sliderVariant1ComponentSchema'
import { ELayoutGroupType } from '../meta/ELayoutGroupType'

const slideComponentSchema = containerComponentSchema.shape({
  id: idSchema.required(),
  components: object({
    contentContainer: containerComponentSchema,
    heading: textComponentSchema,
    text: textComponentSchema,
    background: mediaComponentSchema,
    buttons: containerComponentSchema
      .shape({
        items: array().of(
          buttonComponentSchema
            .shape({
              id: idSchema.required(),
            })
            .noUnknown(),
        ),
      })
      .noUnknown(),
  }).noUnknown(),
})

export const heroCarousel1BlockSchema = object({
  id: idSchema,
  version: version(1),
  components: object({
    block: blockComponentSchema
      .shape({
        swapOrder: bool(),
        columnRatio: number().min(1).max(12),
      })
      .noUnknown(),
    slider: sliderVariant1ComponentSchema
      .shape({
        components: sliderVariant1ComponentsSchema.shape({
          slides: containerComponentSchema.shape({
            items: array().of(slideComponentSchema.noUnknown()).required(),
          }),
        }),
      })
      .noUnknown(),
  }).noUnknown(),
  config: object().optional(),
  preview: bool(),
  editor: blockEditorSchema,
})
  .noUnknown()
  .required()
  .meta({
    layoutGroupType: ELayoutGroupType.FULL,
  })

export type HeroCarousel1Block = InferType<typeof heroCarousel1BlockSchema>
