import { InferType, array, bool, object } from 'yup'
import { blockComponentSchema } from '../../components/blockComponentSchema'
import { buttonComponentSchema } from '../../components/buttonComponentSchema'
import { containerComponentSchema } from '../../components/containerComponentSchema'
import { imageComponentSchema } from '../../components/imageComponentSchema'
import { mediaComponentSchema } from '../../components/mediaComponentSchema'
import { textComponentSchema } from '../../components/textComponentSchema'
import { blockEditorSchema } from '../../shared/blockEditorSchema'
import { idSchema } from '../../shared/idSchema'
import { version } from '../../shared/versionSchema'
import { gridComponentSchema } from '../../components/gridComponentSchema'
import { ELayoutGroupType } from '../meta/ELayoutGroupType'

export const heroWithLogo1BlockSchema = object({
  id: idSchema,
  version: version(1),
  components: object({
    block: blockComponentSchema,
    tagline: textComponentSchema,
    text: textComponentSchema,
    heading: textComponentSchema,
    background: mediaComponentSchema,
    author: textComponentSchema,
    logosWithText: containerComponentSchema.shape({
      components: object({
        logos: gridComponentSchema
          .shape({
            items: array().of(
              imageComponentSchema
                .shape({
                  id: idSchema.required(),
                })
                .noUnknown(),
            ),
          })
          .noUnknown(),
        text: textComponentSchema,
      })
        .noUnknown()
        .required(),
    }),
    buttons: containerComponentSchema
      .shape({
        items: array().of(
          buttonComponentSchema
            .shape({
              id: idSchema.required(),
            })
            .noUnknown(),
        ),
      })
      .noUnknown(),
  }).noUnknown(),
  config: object().optional(),
  preview: bool(),
  editor: blockEditorSchema,
})
  .noUnknown()
  .required()
  .meta({
    layoutGroupType: ELayoutGroupType.FULL,
  })

export type HeroWithLogo1Block = InferType<typeof heroWithLogo1BlockSchema>
