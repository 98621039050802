import React, { useEffect, useState } from 'react'
import {
  // eslint-disable-next-line import/named
  FallbackProps,
  ErrorBoundary as LibErrorBoundary,
} from 'react-error-boundary'
import posthog from 'posthog-js'

type ErrorBoundarySessionType = {
  reloads: number
  lastReloadAt: number
}

function processError(error: Error, info: { componentStack: string }) {
  posthog.captureException(error)
  // Do something with the error, e.g. log to an external API
  // console.log('[error-boundary]', { error, info })
  return { message: '[error-boundary]', log: { error, info } }
}

const sessionKey = 'error:boundary:dynamic:import'
const sessionReloadDiffInSec = 10

function useReloadInterval({
  isDynamicImportError,
}: {
  isDynamicImportError: boolean
}) {
  if (!isDynamicImportError) return

  const attemps = 2
  const timeoutInSeconds = 1
  let timeout = null as null | NodeJS.Timeout

  const [refreshIn, setRefreshIn] = useState(timeoutInSeconds)

  useEffect(() => {
    const now = Date.now()

    async function reload() {
      if (timeout) clearTimeout(timeout)

      if (!isDynamicImportError) {
        return
      }

      const existingSessionValue = window.sessionStorage.getItem(sessionKey)
      let session: ErrorBoundarySessionType | null = existingSessionValue
        ? JSON.parse(existingSessionValue)
        : null

      const lastReloadAt = session?.lastReloadAt || 0
      const isDateCheckExpired =
        Math.round(now - lastReloadAt) / 1000 > sessionReloadDiffInSec

      // reset state after date is expired
      if (isDateCheckExpired) {
        session = { reloads: 0, lastReloadAt: 0 }
        window.sessionStorage.removeItem(sessionKey)
      }

      // check number of reloads + expiration
      if ((session?.reloads || 0) >= attemps && !isDateCheckExpired) {
        // console.log('break')
        return
      }

      if (refreshIn === 0) {
        window.sessionStorage.setItem(
          sessionKey,
          JSON.stringify({
            ...session,
            reloads: (session?.reloads || 0) + 1,
            lastReloadAt: now,
          }),
        )

        // reload browser TAB (active only)
        window.location.reload()

        // break execution
        return
      }

      timeout = setTimeout(
        () => setRefreshIn((prevRefreshIn) => prevRefreshIn - 1),
        1000,
      )
    }

    reload()

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [refreshIn])

  return {
    refreshInSeconds: refreshIn,
  }
}

function ErrorFallback({ error }: FallbackProps) {
  const errorString = error.toString()
  const isDynamicImportError =
    /(Failed to fetch dynamically imported module|Importing a module script failed)/i.test(
      errorString,
    )

  const { refreshInSeconds } = useReloadInterval({ isDynamicImportError }) || {}

  // handle specific error (return null shows no UI)
  if (!isDynamicImportError) return null

  return null

  // TODO - specific error UI
  // return (
  //   <div role="alert">
  //     <p>Something went wrong:</p>
  //     <p>
  //       <pre style={{ color: 'red' }}>{error.message}</pre>
  //     </p>
  //     <p>This page will reload in {refreshInSeconds} seconds.</p>
  //   </div>
  // )
}

export function ErrorBoundary({ children }: { children: React.ReactNode }) {
  // if (import.meta.env.DEV === true) {
  //   return <>{children}</>
  // }

  return (
    <LibErrorBoundary FallbackComponent={ErrorFallback} onError={processError}>
      {children}
    </LibErrorBoundary>
  )
}
