import { InferType, bool, number, object } from 'yup'
import { blockComponentSchema } from '../../components/blockComponentSchema'
import { containerComponentSchema } from '../../components/containerComponentSchema'
import { imageComponentSchema } from '../../components/imageComponentSchema'
import { mediaComponentSchema } from '../../components/mediaComponentSchema'
import { textComponentSchema } from '../../components/textComponentSchema'
import { blockEditorSchema } from '../../shared/blockEditorSchema'
import { idSchema } from '../../shared/idSchema'
import { version } from '../../shared/versionSchema'
import { formComponentSchema } from '../../components/formComponentSchema'
import { ELayoutGroupType } from '../meta/ELayoutGroupType'

export const heroWithForm1BlockSchema = object({
  id: idSchema,
  version: version(1),
  components: object({
    block: blockComponentSchema
      .shape({
        swapOrder: bool(),
        columnRatio: number().min(1).max(12),
      })
      .noUnknown(),
    logo: imageComponentSchema,
    subheading: textComponentSchema,
    text: textComponentSchema,
    heading: textComponentSchema,
    media: mediaComponentSchema,
    author: textComponentSchema,
    mediaContainer: containerComponentSchema,
    contentContainer: containerComponentSchema,
    form: formComponentSchema,
  }).noUnknown(),
  config: object().optional(),
  preview: bool(),
  editor: blockEditorSchema,
})
  .noUnknown()
  .required()
  .meta({
    layoutGroupType: ELayoutGroupType.SPLIT,
  })

export type HeroWithForm1Block = InferType<typeof heroWithForm1BlockSchema>
