import { InferType, bool, number, object } from 'yup'
import { blockComponentSchema } from '../../components/blockComponentSchema'
import { textComponentSchema } from '../../components/textComponentSchema'
import { blockEditorSchema } from '../../shared/blockEditorSchema'
import { idSchema } from '../../shared/idSchema'
import { version } from '../../shared/versionSchema'
import { formComponentSchema } from '../../components/formComponentSchema'
import { ELayoutGroupType } from '../meta/ELayoutGroupType'

export const ctaBanner4BlockSchema = object({
  id: idSchema,
  version: version(1),
  components: object({
    block: blockComponentSchema
      .shape({
        swapOrder: bool(),
        columnRatio: number().min(1).max(12),
      })
      .noUnknown(),
    tagline: textComponentSchema,
    text: textComponentSchema,
    heading: textComponentSchema,
    form: formComponentSchema,
    bottomText: textComponentSchema,
  }).noUnknown(),
  config: object().optional(),
  preview: bool(),
  editor: blockEditorSchema,
})
  .noUnknown()
  .required()
  .meta({
    layoutGroupType: ELayoutGroupType.SPLIT,
  })

export type CTABanner4Block = InferType<typeof ctaBanner4BlockSchema>
